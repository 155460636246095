import React, { useCallback, useState } from 'react';
import { useAlleTreatments } from '@allergan-data-labs/core-split-feature-flag/src/useAlleTreatments';
import { caFeatureKeys } from '@allergan-data-labs/core-split-feature-flag/src/consumer';
import { useSegment } from '@allergan-data-labs/deprecated-consumer-component-library/src/segment/segmentContext';
import { SiteNavigated } from '@allergan-data-labs/deprecated-consumer-component-library/src/segment-tracking-plan/generated';
import { ALLERGAN_URLS } from '@allergan-data-labs/deprecated-universal-component-library/src/constants/urls';
import { PrivacyDialog } from '@allergan-data-labs/deprecated-consumer-component-library/src/components/privacyDialog';
import { ConsumerFooter } from '@allergan-data-labs/deprecated-consumer-component-library/src/footers/consumerFooter';
import { ConsumerFooterProps } from '@allergan-data-labs/deprecated-consumer-component-library/src/footers/consumerFooterHelpers';
import { ConsumerFooterLink } from '@allergan-data-labs/deprecated-consumer-component-library/src/footers/consumerFooterLink';
import { ConsumerFooterCookieSettings } from '@allergan-data-labs/deprecated-consumer-component-library/src/footers/consumerFooterCookieSettings';
import { ConsumerFooterPrivacyChoices } from '@allergan-data-labs/deprecated-consumer-component-library/src/footers/consumerFooterPrivacyChoices';

// constants
import { Routes } from '../../constants/routes';
import { ConsumerFooter as ConsumerFooterDeprecated } from './consumerFooterDEPRECATED';
import { useAuth } from '../../auth/AuthProvider';
import { AuthState } from '../../auth/authMachine';

const ConsumerFooterWrapper = (props: ConsumerFooterProps) => {
  const { authState } = useAuth();
  const isLoggedIn = authState?.matches(AuthState.isLoggedIn);
  const { trackConsumer } = useSegment();
  const [isPrivacyDialogOpen, setIsPrivacyDialogOpen] = useState(false);

  const trackHelpLinkClick = () => {
    trackConsumer()?.helpRequested({
      event: {
        action_source: 'consumer web',
        activator: 'Footer',
        explainer: 'Tracking user engagement with footer',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: isLoggedIn ? 'logged_in' : 'logged_out',
      },
    });
  };

  const trackLinkClick = useCallback(
    (e: React.SyntheticEvent) => {
      const link = e.currentTarget as HTMLElement;
      const linkSegment: SiteNavigated = {
        event: {
          action_source: 'consumer web',
          activator: `Footer - ${link.textContent} Link`,
          explainer: 'Tracking user engagement with Footer',
          invoked_by: 'consumer',
          user_type: 'consumer',
          user_status: isLoggedIn ? 'logged_in' : 'logged_out',
          event_type: 'engagement',
        },
        site_type: 'consumer web',
        navigation_type: 'footer navigation',
        event_source_url: `${window.location.href}`,
        text: `${link.textContent}`,
        url: `${link.getAttribute('href')}`,
      };

      trackConsumer()?.siteNavigated(linkSegment);
    },
    [trackConsumer, isLoggedIn]
  );

  const trackOneTrustButtonClick = useCallback(() => {
    trackConsumer()?.siteNavigated({
      event: {
        action_source: 'consumer web',
        activator: 'Footer',
        explainer: 'Tracking if user interacts with footer',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: isLoggedIn ? 'logged_in' : 'logged_out',
        event_type: 'engagement',
      },
      navigation_type: 'footer navigation',
      site_type: 'consumer web',
      text: 'One Trust',
      event_source_url: `${window.location.href}`,
    });
  }, [trackConsumer, isLoggedIn]);

  const openCookieSettings = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();

    (window as any).OneTrust?.ToggleInfoDisplay();
  };

  const handleOneTrustButtonClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    openCookieSettings(e);
    trackOneTrustButtonClick();
  };

  const handleYourPrivacyChoicesClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    const isLinkEvent = e.currentTarget instanceof HTMLAnchorElement;

    trackConsumer()?.siteNavigated({
      event: {
        action_source: 'consumer web',
        activator: 'Footer Link',
        explainer: 'Tracking user engagement with link on footer',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: isLoggedIn ? 'logged_in' : 'logged_out',
        event_type: 'engagement',
      },
      site_type: 'consumer web',
      navigation_type: 'footer navigation',
      event_source_url: window.location.href,
      text: 'Your Privacy Choices',
      url: isLinkEvent ? Routes.settingsPrivacy : undefined,
    });

    if (isLoggedIn) {
      return;
    }

    e.preventDefault();
    setIsPrivacyDialogOpen(true);
  };

  const StaticCodeComponents: Record<string, JSX.Element | null> = {
    cookieSettingsLink: !isLoggedIn ? (
      <ConsumerFooterCookieSettings onClick={handleOneTrustButtonClick} />
    ) : null,
    privacyChoicesLink: (
      <ConsumerFooterPrivacyChoices
        onClick={handleYourPrivacyChoicesClick}
        redirectTo={isLoggedIn ? Routes.settingsPrivacy : null}
      />
    ),
  };

  return (
    <>
      <PrivacyDialog
        open={isPrivacyDialogOpen}
        isLoggedIn={isLoggedIn}
        loginRoute={Routes.login}
        onClose={() => setIsPrivacyDialogOpen(false)}
        openCookieSettings={openCookieSettings}
      />
      <ConsumerFooter
        {...props}
        isLoggedIn={isLoggedIn}
        renderLink={(link) => {
          const { url } = link;
          const isSupportLink = url?.includes(ALLERGAN_URLS.alleSupport);
          return (
            <ConsumerFooterLink
              link={link}
              onClick={isSupportLink ? trackHelpLinkClick : trackLinkClick}
            />
          );
        }}
        renderStaticCodeComponent={(staticCode) => {
          const { codeId } = staticCode;
          return codeId ? StaticCodeComponents[codeId] : null;
        }}
      />
    </>
  );
};

// PD-4433: We'll remove this switch after removing the consumer_footerRedesign_web FF (PD-4434)
const ConsumerFooterSwitch = (props: ConsumerFooterProps) => {
  const isFooterRedesignEnabled =
    useAlleTreatments({
      splitNames: [caFeatureKeys.footerRedesign],
    })[caFeatureKeys.footerRedesign]?.treatment === 'on';

  if (isFooterRedesignEnabled) {
    return <ConsumerFooterWrapper {...props} />;
  } else {
    return <ConsumerFooterDeprecated {...props} />;
  }
};

export { ConsumerFooterSwitch as ConsumerFooter };
