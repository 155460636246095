import React from 'react';
import {
  IBlockFooterFields,
  ILinkFields,
  IStaticCodeFields,
} from '@allergan-data-labs/core-contentful-rest';
import { RichText } from '@allergan-data-labs/deprecated-component-library/src/components/richText/richText';

export interface ConsumerFooterProps {
  copyright: React.ReactNode;
  PRT?: string;
  hideGlobalISILink?: boolean;
  hideAbout?: boolean;
  links?: Array<ILinkFields | IStaticCodeFields>;
}

// PD-4433: this should become the default type once we remove the consumer_footerRedesign_web FF (PD-4434)
export interface ConsumerFooterRedesignProps extends ConsumerFooterProps {
  isLoggedIn?: boolean;
  renderLink: (link: ILinkFields) => JSX.Element;
  renderStaticCodeComponent: (
    staticCode: IStaticCodeFields
  ) => JSX.Element | null;
}

// PRT prop must be passed in to component seperately, depending on whether or not PRT is part of copyright
export const cmsConsumerFooterToProps = (
  contentfulFooter?: IBlockFooterFields | null
): Omit<ConsumerFooterProps, 'PRT'> => {
  return {
    hideGlobalISILink: contentfulFooter?.showGlobalIsi === false || false,
    hideAbout: contentfulFooter?.showAboutLinks === false || false,
    copyright: (
      <RichText
        richText={contentfulFooter?.footerCopy}
        paragraphPreset={{
          s: 'body-S-regular',
          m: 'body-S-regular',
          l: 'body-M-regular',
        }}
      />
    ),
    links: contentfulFooter?.linksReference?.[0].fields.links.map(
      (link) => link.fields
    ),
  };
};

export const isStaticCode = (
  link: ILinkFields | IStaticCodeFields | null
): link is IStaticCodeFields => {
  return (link as IStaticCodeFields)?.codeId !== undefined;
};
