import React from 'react';
import styled from 'styled-components';

import { above } from '@allergan-data-labs/deprecated-universal-component-library/src/utils/mediaTemplate';
import { Link } from '../link/link';
import { H5, bodyText2 } from '../typography/typographyDEPRECATED';
import { AlleLogo } from '../../icons/alleLogo';
import { TwitterLogo } from '../../icons/twitterLogo';
import { FacebookLogo } from '../../icons/facebookLogo';
import { InstagramLogo } from '../../icons/instagramLogo';
import { alleColors } from '../../colorPalette';

const HtmlFooter = styled.footer`
  background-color: ${alleColors.gray[1000]};

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  flex: 1;
  padding: 104px 32px 48px 32px;
  max-width: 1200px;
`;

export const TopRow = styled.div`
  @media print {
    display: none;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  & .MuiSvgIcon-root {
    ${above.desktop`
      margin-left: 0;
    `}
  }
  & .MuiSvgIcon-root path {
    fill: ${alleColors.white};
  }
`;

export const SectionsContainer = styled.div`
  display: flex;

  flex-direction: column;
  width: 50%;

  ${above.desktop`
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    width: auto;
  `}
`;

type SectionProps = {
  nested?: boolean;
};

const Section = styled.div<SectionProps>`
  ${above.desktop`
    min-width: 12%;
  `}

  a p {
    ${bodyText2({ variant: 'bodyText2', weight: 'normal' })}
    color: ${alleColors.white};
    margin-bottom: 18px;
  }

  button p {
    color: ${alleColors.white};
  }

  & + & {
    margin-top: 24px;
    ${above.desktop`
      margin-top: 0;
      margin-left: 12%;
    `}
  }

  ${(props) => (props.nested ? 'margin-bottom: 48px;' : '')}
`;

const SectionLabel = styled(H5).attrs({ as: 'h2' })`
  margin-top: 0;
  margin-bottom: 25px;
  text-transform: uppercase;
  color: #d09284;
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 116px;

  ${above.desktop`
    flex-direction: row-reverse;
    justify-content: space-between;
  `}
`;

const SocialIconContainer = styled.div`
  @media print {
    display: none;
  }

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  a {
    margin-right: 24px;

    svg.MuiSvgIcon-root path {
      fill: ${alleColors.white};
    }
  }

  ${above.desktop`
    a {
      margin-right: 0;
    }
    a + a {
      margin-left: 24px;
    }
  `}
`;

export const Logo = styled(AlleLogo)``;

interface SocialUrls {
  twitter?: string;
  facebook?: string;
  instagram?: string;
}

export interface FooterProps {
  children?: React.ReactNode;
  socialUrls?: SocialUrls;
  copyrightSection?: JSX.Element;
  className?: string;
}

const SocialLink = ({ url, Icon }: { url?: string; Icon: any }) =>
  url ? (
    <Link external to={url} css="margin-bottom: 24px;">
      <Icon />
    </Link>
  ) : null;

export interface FooterInterface extends React.FunctionComponent<FooterProps> {
  children?: React.ReactNode;
  Section: React.FC<React.PropsWithChildren<SectionProps>>;
  SectionLabel: React.FC<React.PropsWithChildren<{}>>;
}

const Footer: FooterInterface = ({
  socialUrls = {},
  copyrightSection,
  children,
  className,
}) => {
  return (
    <HtmlFooter className={className} data-testid="page-footer">
      <Container>
        <TopRow>
          <Logo />
          <SectionsContainer>{children}</SectionsContainer>
        </TopRow>
        <BottomRow>
          <SocialIconContainer>
            <SocialLink url={socialUrls.twitter} Icon={TwitterLogo} />
            <SocialLink url={socialUrls.facebook} Icon={FacebookLogo} />
            <SocialLink url={socialUrls.instagram} Icon={InstagramLogo} />
          </SocialIconContainer>
          {copyrightSection}
        </BottomRow>
      </Container>
    </HtmlFooter>
  );
};

Footer.Section = Section;
Footer.SectionLabel = SectionLabel;

export { Footer };
