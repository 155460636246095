import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Typography } from '@allergan-data-labs/deprecated-universal-component-library/src/atoms/typography';
import {
  above,
  screenSizes,
} from '@allergan-data-labs/deprecated-universal-component-library/src/utils/mediaTemplate';

// component library
import { colors } from '@allergan-data-labs/deprecated-component-library/src/core/colors';
import { Link } from '@allergan-data-labs/deprecated-component-library/src/core/link';
import { AlleLogo } from '@allergan-data-labs/deprecated-component-library/src/icons/alleLogo';
import {
  H3,
  P,
} from '@allergan-data-labs/deprecated-component-library/src/core/typography/typographyDEPRECATED';
import { SimpleIconButton } from '@allergan-data-labs/deprecated-component-library/src/core/simpleIconButton';
import { AlleButton as Button } from '@allergan-data-labs/deprecated-component-library/src/core/alleButton';
import { HeaderContainer } from '@allergan-data-labs/deprecated-component-library/src/header/headerContainer';
import { Menu } from '@allergan-data-labs/deprecated-component-library/src/icons/menu';
import { Close } from '@allergan-data-labs/deprecated-component-library/src/icons/close';
import { alleColors } from '@allergan-data-labs/deprecated-component-library/src/colorPalette';
import {
  DrawerButtonContainer,
  DrawerContentContainer,
  DrawerNavButtonContainer,
} from '@allergan-data-labs/deprecated-component-library/src/components/drawerStyledComponents';

import { useSegment } from '@allergan-data-labs/deprecated-consumer-component-library/src/segment/segmentContext';
import {
  SiteNavigated,
  ElementEngaged,
} from '@allergan-data-labs/deprecated-consumer-component-library/src/segment-tracking-plan/generated';

// constants
import { Routes } from '../../constants/routes';

const Drawer = React.lazy(() =>
  import(
    /* webpackChunkName: 'Drawer' */ '@allergan-data-labs/deprecated-component-library/src/core/drawer'
  ).then((mod) => ({
    default: mod.Drawer,
  }))
);

const StyledHeaderContainer = styled(HeaderContainer)<{
  $compact?: boolean;
}>`
  && {
    align-items: center;
    padding-top: 37px;
    padding-bottom: 37px;
    padding-left: 26px;
    padding-right: 23px;

    ${above.desktop`
      position: absolute;
      padding-left: 44px;
      padding-right: 44px;
    `}

    ${({ $compact }) =>
      $compact
        ? `
      height: 74px;
      padding-top: 0;
      padding-bottom: 0;
    `
        : ''}

    ${({ $compact }) =>
      $compact
        ? above.tablet`
        height: 63px;
      `
        : ''};

    ${({ $compact }) =>
      $compact
        ? above.desktop`
          height: 70px;
          position: relative;
        `
        : ''};
  }
`;

const StyledSimpleIconButton = styled(SimpleIconButton)`
  &&:hover .MuiSvgIcon-root path {
    fill: ${colors.white};
  }
`;

const LogoLink = styled(Link)<{
  $compact?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  && svg {
    ${({ $compact }) =>
      $compact
        ? `
          width: 54px;
          `
        : ''}

    ${({ $compact }) =>
      $compact
        ? above.tablet`
        height: 42px;
      `
        : ''};

    ${({ $compact }) =>
      $compact
        ? above.desktop`
        height: 48px;
      `
        : ''};
  }
`;

const LoginLink = styled(Link)<{
  $compact?: boolean;
}>`
  text-decoration: none;
  height: 100%;

  :first-child {
    margin-right: 36px;
  }
  ${({ $compact }) =>
    $compact
      ? `
        :first-child {
          margin-right: 16px;
        }
        p {
          color: ${alleColors.nude[600]};
          &:hover { opacity: 0.75; }
        }
      `
      : ''};
  ${({ $compact }) =>
    $compact
      ? above.desktop`
      :first-child {
        margin-right: 32px;
      }
    `
      : ''};
`;

const JoinLink = styled(Link)`
  box-sizing: border-box;
  max-width: 168px;
  border: 1px solid ${alleColors.black};
  background-color: ${alleColors.black};
  border-radius: 4px;
  padding: 8px 16px;
  p {
    color: ${alleColors.white};
  }
  text-align: center;
  &:hover {
    background-color: ${alleColors.white};
    p {
      color: ${alleColors.black};
    }
  }
`;

const LinksContainer = styled.div<{
  $compact?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1023px) {
    ${({ $compact }) => (!$compact ? 'display: none' : '')};
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-root {
    background-color: transparent;
    padding: 12px 50px;
  }
`;

const DrawerFallback: React.FunctionComponent = () => {
  // Drawer is rendered off screen until visible, no need to display a fallback.
  return null;
};

const LoggedOutHeader: React.FunctionComponent<{
  compact?: boolean;
  variant: 'light' | 'transparent' | 'transparentBlackText';
  keepPathOnLogin?: boolean;
  jumpLink?: string;
}> = ({ compact, variant, keepPathOnLogin, jumpLink }) => {
  const isDesktop = useMediaQuery(`(min-width:${screenSizes.desktop}px)`);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const location = history.location;
  const { trackConsumer } = useSegment();

  const getLoginRoute = () => {
    if (keepPathOnLogin) {
      return { pathname: Routes.login, state: { from: location.pathname } };
    }
    return Routes.login;
  };

  const getJoinRoute = () => {
    if (jumpLink) {
      return `${location.pathname}${jumpLink}`;
    } else {
      return Routes.registrationPhoneNumber;
    }
  };

  const handleHamburgerMenuClick = React.useCallback(() => {
    setOpen(true);
    const hamburgerMenuSegment: ElementEngaged = {
      action: 'Open',
      event: {
        activator: 'Top Nav',
        explainer: 'Tracking user engagement with top navigation',
        extra_data: {
          properties: 'Hamburger Menu',
        },
        invoked_by: 'consumer',
        // @ts-ignore
        type: 'consumer',
      },
    };

    trackConsumer()?.elementEngaged(hamburgerMenuSegment);
  }, [trackConsumer]);

  const handleAlleLinkClick = React.useCallback(() => {
    const linkSegment: SiteNavigated = {
      event: {
        action_source: 'consumer web',
        activator: ' Logged Out - Allē Logo',
        explainer: 'Tracking user engagement with top navigation',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: 'logged_out',
        event_type: 'engagement',
      },
      site_type: 'consumer web',
      navigation_type: 'top navigation',
      event_source_url: `${window.location.href}`,
      text: 'Alle',
      url: `https://alle.com/`,
    };
    trackConsumer()?.siteNavigated(linkSegment);
  }, [trackConsumer]);

  const handleLoginLinkClick = React.useCallback(() => {
    const linkSegment: SiteNavigated = {
      event: {
        action_source: 'consumer web',
        activator: 'Log in Button',
        explainer: 'Tracking user engagement with top navigation',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: 'logged_out',
        event_type: 'engagement',
      },
      site_type: 'consumer web',
      navigation_type: 'top navigation',
      event_source_url: `${window.location.href}`,
      text: 'Log in',
      url: `https://alle.com/login`,
    };
    trackConsumer()?.siteNavigated(linkSegment);
  }, [trackConsumer]);

  const handleJoinAlleClick = React.useCallback(() => {
    const linkSegment: SiteNavigated = {
      event: {
        action_source: 'consumer web',
        activator: 'Join Allē Button',
        explainer: 'Tracking user engagement with top navigation',
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: 'logged_out',
        event_type: 'engagement',
      },
      site_type: 'consumer web',
      navigation_type: 'top navigation',
      event_source_url: `${window.location.href}`,
      text: 'Join Allē',
      url: `https://alle.com/registration`,
    };

    trackConsumer()?.siteNavigated(linkSegment);
  }, [trackConsumer]);

  return (
    <>
      <StyledHeaderContainer
        data-testid="logged-out-header"
        variant={variant}
        $compact={compact}
      >
        <LogoLink
          aria-label="ALLE"
          to={Routes.home}
          $compact={compact}
          onClick={handleAlleLinkClick}
        >
          <AlleLogo dark={isDesktop && variant === 'transparent'} />
        </LogoLink>
        {compact ? (
          <LinksContainer $compact={compact}>
            <LoginLink
              $compact={compact}
              data-testid="header-login-link"
              to={getLoginRoute()}
              onClick={handleLoginLinkClick}
            >
              <Typography
                preset={{
                  s: 'body-M-regular',
                  m: 'body-M-regular',
                  l: 'body-M-regular',
                }}
              >
                LOG IN
              </Typography>
            </LoginLink>
            <JoinLink
              data-testid="header-join-link"
              to={getJoinRoute()}
              onClick={handleJoinAlleClick}
            >
              <Typography
                preset={{
                  s: 'body-M-regular',
                  m: 'body-M-regular',
                  l: 'body-M-regular',
                }}
              >
                JOIN ALLĒ
              </Typography>
            </JoinLink>
          </LinksContainer>
        ) : !isDesktop ? (
          <SimpleIconButton
            onClick={handleHamburgerMenuClick}
            color={colors.black}
            data-testid="open-menu-button"
            aria-label="Menu button"
          >
            <Menu />
          </SimpleIconButton>
        ) : (
          <LinksContainer>
            <LoginLink
              data-testid="header-login-link"
              to={getLoginRoute()}
              onClick={handleLoginLinkClick}
            >
              <P
                variant="bodyText3"
                weight="normal"
                color={variant === 'transparent' ? colors.white : colors.black}
              >
                LOG IN
              </P>
            </LoginLink>
            <LoginLink
              data-testid="header-join-link"
              to={getJoinRoute()}
              onClick={handleJoinAlleClick}
            >
              <StyledButton
                buttonType="outlined"
                variant={variant === 'transparent' ? 'dark' : 'light'}
              >
                JOIN NOW
              </StyledButton>
            </LoginLink>
          </LinksContainer>
        )}
      </StyledHeaderContainer>
      <React.Suspense fallback={<DrawerFallback />}>
        <Drawer open={open} onClose={() => setOpen(false)}>
          <DrawerButtonContainer>
            <StyledSimpleIconButton
              onClick={() => setOpen(false)}
              color={colors.white}
            >
              <Close />
            </StyledSimpleIconButton>
          </DrawerButtonContainer>
          <DrawerContentContainer>
            <DrawerNavButtonContainer>
              <Link
                to={getJoinRoute()}
                data-testid={`drawer-link-join`}
                onClick={handleJoinAlleClick}
              >
                <H3 fontFamily="graphik" color={colors.white}>
                  Join Now
                </H3>
              </Link>
            </DrawerNavButtonContainer>
            <DrawerNavButtonContainer>
              <Link
                to={getLoginRoute()}
                data-testid={`drawer-link-login`}
                onClick={handleLoginLinkClick}
              >
                <H3 fontFamily="graphik" color={colors.white}>
                  Log In
                </H3>
              </Link>
            </DrawerNavButtonContainer>
          </DrawerContentContainer>
        </Drawer>
      </React.Suspense>
    </>
  );
};

export { LoggedOutHeader };
