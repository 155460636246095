import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { LoadingContext } from '@allergan-data-labs/deprecated-component-library/src/loadingBar/loadingContext';
import { Timezones } from '@allergan-data-labs/deprecated-component-library/src/utils/timezone';
import { ConsumerProfileQueryDeprecated } from '../../../utils/hooks/useLazyConsumerProfile';

const QUERY_PROFILE = gql`
  query ConsumerAccountDashboard {
    viewer {
      id
      profile {
        __typename
        ... on ConsumerProfile {
          id
          firstName
          loyalty {
            id
            isBDMember
            tier
            points {
              id
              available
            }
            promotionsConnection(limit: 3) {
              offsetPageInfo {
                totalResults
              }
              nodes {
                id
                imageURL
                imageAltText
                imageDisclaimerText
                hideImageInWallet
                name
                expiration {
                  format(layout: "MM.DD.YY", timezone: "${Timezones.PACIFIC.HONOLULU}")
                }
                displayName
                description
                construct {
                  advertisingCampaign {
                    id
                  }
                }
                redeemablePractice {
                  advertisingProfile {
                    advertisingLeadNotificationMethods {
                      textMessage {
                        id
                        phoneNumber
                        isEnabled
                        verificationStatus
                      }
                    }
                    contact {
                      address {
                        address1
                        address2
                        city
                        state
                        zipCode
                        fullAddress
                      }
                      displayName
                      avatarUrl
                      phoneNumber
                    }
                  }
                }
                value {
                  unit
                  quantity
                }
                isisConnection {
                  isis {
                    id
                    name
                    medicationGuideUrl
                    content {
                      consumerHTML
                      majorStatementFirstLineHTML
                    }
                    prescribingInformation {
                      statementHTML
                    }
                    consumerContent {
                      slug
                      title {
                        source
                      }
                      shortTitle {
                        source
                      }
                      main {
                        source
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useAccountDashboardData = () => {
  const { setIsLoading } = React.useContext(LoadingContext);

  const {
    data: apolloData,
    error: apolloError,
    loading: apolloLoading,
    refetch,
  } = useQuery<ConsumerProfileQueryDeprecated>(QUERY_PROFILE, {
    fetchPolicy: 'cache-first',
  });

  React.useEffect(() => {
    setIsLoading(apolloLoading);
  }, [apolloLoading, setIsLoading]);

  return {
    refetch,
    status: apolloError
      ? ('error' as const)
      : apolloLoading
      ? ('loading' as const)
      : ('completed' as const),
    profile: apolloData?.viewer?.profile,
    error: apolloError,
  };
};

export { useAccountDashboardData };
