import React from 'react';

import { Box } from '@allergan-data-labs/alle-elements-layout';
import { ILinkFields } from '@allergan-data-labs/core-contentful-rest';

import { CmsLink } from '../chakra-components/cmsLink';

type FooterLinkProps = {
  link: ILinkFields;
  onClick: (e: React.SyntheticEvent) => void;
};

export function ConsumerFooterLink({ link, onClick }: FooterLinkProps) {
  const { url, displayName, forceOpenSameTab } = link;

  return (
    <CmsLink
      data-testid={`footer-${displayName?.split(' ').join('-').toLowerCase()}`}
      href={url as string}
      forceOpenSameTab={forceOpenSameTab}
      onClick={onClick}
      sx={{
        textDecoration: 'none',
      }}
      isStandalone={true}
      variant="action"
      colorMode="dark"
    >
      <Box
        textStyle="Body/Small/Regular"
        color={`dark.Text/Neutral/Interactive/Enable`}
        textAlign="center"
      >
        {displayName}
      </Box>
    </CmsLink>
  );
}
