import React from 'react';
import styled from 'styled-components';
// Material
import Collapse from '@material-ui/core/Collapse';

import { Typography } from '@allergan-data-labs/deprecated-universal-component-library/src/atoms/typography';
import { above } from '@allergan-data-labs/deprecated-universal-component-library/src/utils/mediaTemplate';
// Core
import { colors } from '../core/colors';
import { SimpleIconButton } from '../core/simpleIconButton';
import { Close } from '../icons/close';
import {
  DEFAULT_GROUP_NAME,
  NotificationContext,
  NotificationOptionsInternal,
} from './notificationContext';
import { alleColors } from '../colorPalette';

const NotificationBarWrapper = styled.div<{
  $size?: 'medium' | 'large';
}>`
  height: ${(props) => (props.$size === 'large' ? '72px' : '48px')};
  width: 100vw;
  max-width: 100%;
`;

const NotificationBarContainer = styled.div<{
  $backgroundColor: string;
  $size?: 'medium' | 'large';
}>`
  pointer-events: auto;
  position: absolute;
  height: ${(props) => (props.$size === 'large' ? '72px' : '48px')};
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 72px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.$backgroundColor};
`;

const Wrapper = styled.div`
  position: sticky;
  top: 0px;
  pointer-events: none;
`;

const Container = styled.div`
  position: absolute;
  height: 80vh;
  width: 100vw;
  max-width: 100%;
`;

const CloseButtonContainer = styled.div<{
  $size?: 'medium' | 'large';
  $responsive?: boolean;
}>`
  position: absolute;
  top: 0;
  right: 23px;
  bottom: 0;
  height: ${(props) => (props.$size === 'large' ? '72px' : '48px')};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $responsive }) =>
    $responsive
      ? above.desktop`
      right: 50px;
    `
      : ``}
`;

const NotificationText = styled(Typography).attrs({
  component: 'p',
  preset: 'body-M-regular',
})`
  text-align: center;
  line-height: 1;
  a {
    color: ${colors.white};
  }
`;

const StyledCollapse = styled(Collapse)`
  position: relative;
`;

const IconContainer = styled.div`
  padding: 8px;
`;
interface BaseNotificationBarProps {
  options: NotificationOptionsInternal;
  onClose: () => void;
  responsive?: boolean;
  className?: string;
}

export const BaseNotificationBar: React.FunctionComponent<
  BaseNotificationBarProps
> = ({ options, onClose, responsive, className }) => {
  const variantThemes = {
    general: {
      background: colors.nudeDark,
      text: colors.white,
      closeIconColor: colors.white,
    },
    positive: {
      background: colors.nude,
      text: colors.nudeDark2,
      closeIconColor: colors.nudeDark,
    },
    positiveLight: {
      background: colors.skin2,
      text: colors.white,
      closeIconColor: colors.white,
    },
    warning: {
      background: colors.black,
      text: colors.skin,
      closeIconColor: colors.white,
    },
    error: {
      background: colors.errorRed,
      text: colors.errorRed2,
      closeIconColor: colors.white,
    },
    errorLight: {
      background: colors.pink,
      text: colors.black,
      closeIconColor: colors.black,
    },
    errorLight2: {
      background: '#F9EBE9',
      text: alleColors.ravenBlack,
      closeIconColor: alleColors.ravenBlack,
    },
    success: {
      background: colors.green3,
      text: colors.black,
      closeIconColor: colors.black,
    },
  };

  return (
    <NotificationBarWrapper className={className} $size={options.size}>
      <NotificationBarContainer
        style={options.style}
        $backgroundColor={variantThemes[options.variant].background}
        $size={options.size}
        data-testid={options.id}
      >
        {options.startIcon && (
          <IconContainer>{options.startIcon}</IconContainer>
        )}
        <NotificationText
          css={`
            color: ${variantThemes[options.variant].text};
          `}
        >
          {options.text}
        </NotificationText>
        {options.persistent ||
        (options.timeout && !options.showTimeoutCloseButton) ? null : (
          <CloseButtonContainer $size={options.size} $responsive={responsive}>
            <SimpleIconButton
              aria-label="close-button"
              onClick={onClose}
              color={variantThemes[options.variant].closeIconColor}
              data-testid={`close-${options.id}`}
            >
              <Close />
            </SimpleIconButton>
          </CloseButtonContainer>
        )}
      </NotificationBarContainer>
    </NotificationBarWrapper>
  );
};

interface NotificationBarProps {
  responsive?: boolean;
  zIndex?: number;
  groupName?: string;
  wrapperTopPosition?: string;
}

const NotificationBar: React.FunctionComponent<NotificationBarProps> = ({
  responsive,
  zIndex,
  groupName = DEFAULT_GROUP_NAME,
  wrapperTopPosition,
}) => {
  const { notifications, clearNotification } =
    React.useContext(NotificationContext);
  return (
    <Wrapper style={{ zIndex: zIndex || 100, top: wrapperTopPosition }}>
      <Container>
        {notifications
          .filter((n) => n.groupName === groupName)
          .map((n: NotificationOptionsInternal) => (
            <StyledCollapse
              key={n.internalId}
              in={!n.closed}
              timeout={500}
              appear
              data-testid={`collapsible-notification-${n.id}`}
            >
              <BaseNotificationBar
                options={n}
                onClose={() => clearNotification(n.id)}
                responsive={responsive}
              />
            </StyledCollapse>
          ))}
      </Container>
    </Wrapper>
  );
};

export { NotificationBar };
