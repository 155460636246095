import styled, { css } from 'styled-components';
import { above } from '@allergan-data-labs/deprecated-universal-component-library/src/utils/mediaTemplate';
import { colors } from '../colors';

const graphikFontFamily = "'Graphik', sans-serif";
const petersburgFontFamily = "'Petersburg', serif";

export type TextAlignOptions =
  | 'left'
  | 'right'
  | 'center'
  | 'justify'
  | 'initial'
  | 'inherit';

export interface BodyText {
  weight?: 'normal' | 'bold';
  color?: colors;
  textAlign?: TextAlignOptions;
  growDesktop?: boolean;
  theme?: any;
}

export interface BodyText1 extends BodyText {
  variant: 'bodyText1';
}

export interface BodyText2 extends BodyText {
  variant: 'bodyText2';
}

export interface BodyText3 extends BodyText {
  variant: 'bodyText3';
}

type PTag = BodyText1 | BodyText2 | BodyText3;

const bodyText1 = (props: BodyText1) => `
  margin: 0;
  font-family: ${props.theme?.fonts?.fontFamily || graphikFontFamily};
  font-size: 18px;
  font-weight: ${props.weight ? props.weight : 'normal'};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: ${props.color || colors.black};
  text-align: ${props.textAlign || 'initial'};
`;

const bodyText2 = (props: BodyText2) => `
  margin: 0;
  font-family: ${props.theme?.fonts?.fontFamily || graphikFontFamily};
  font-size: 15px;
  font-weight: ${props.weight === 'normal' ? 'normal' : 'bold'};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.73;
  letter-spacing: normal;
  color: ${props.color || colors.black};
  text-align: ${props.textAlign || 'initial'};
`;

const bodyText3 = (props: BodyText3) => `
  margin: 0;
  font-family: ${props.theme?.fonts?.fontFamily || graphikFontFamily};
  font-size: 13px;
  font-weight: ${props.weight === 'normal' ? 'normal' : 'bold'};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: ${props.color || colors.black};
  text-align: ${props.textAlign || 'initial'};

  ${
    props.growDesktop
      ? above.desktop(bodyText2({ ...props, variant: 'bodyText2' }))
      : ''
  }
`;

const P = styled.p<PTag>`
  ${(props) => {
    switch (props.variant) {
      case 'bodyText2':
        return bodyText2(props as BodyText2);
      case 'bodyText3':
        return bodyText3(props as BodyText3);
      default:
        return bodyText1(props as BodyText1);
    }
  }}
`;

export interface Heading {
  color?: colors;
  textAlign?: TextAlignOptions;
  fontFamily?: 'graphik' | 'petersburg';
  theme?: any;
}

const buildFont = (fontFamily: string) => {
  return css`
    font-family: ${fontFamily};
    font-style: normal;
  `;
};

const baseHeadingStyle = css<Heading>`
  ${(props) => {
    const fontMap = {
      graphik: buildFont(graphikFontFamily),
      petersburg: buildFont(petersburgFontFamily),
      default: props.theme?.fonts?.header || buildFont(graphikFontFamily),
    };

    if (props.fontFamily && fontMap[props.fontFamily]) {
      return fontMap[props.fontFamily];
    }

    return fontMap.default;
  }};
  font-weight: normal;
  font-stretch: normal;
  color: ${(props) => props.color || colors.black};
  text-align: ${(props) => props.textAlign || 'initial'};
`;

const heading1Style = css<Heading>`
  ${baseHeadingStyle};
  font-size: 84px;
  line-height: 0.95;
  letter-spacing: -2px;
`;

const H1 = styled.h1<Heading>`
  ${heading1Style}
`;

const heading2Style = css<Heading>`
  ${baseHeadingStyle};
  font-size: 48px;
  line-height: 1.25;
  letter-spacing: -2px;
`;

const H2 = styled.h2<Heading>`
  ${heading2Style};
`;

const heading3Style = css<Heading>`
  ${baseHeadingStyle};
  font-size: 36px;
  line-height: 1.44;
  letter-spacing: -1.6px;
`;

const H3 = styled.h3<Heading>`
  ${heading3Style};
`;

const heading4Style = css<Heading>`
  ${baseHeadingStyle};
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: -0.8px;
`;

const H4 = styled.h4<Heading>`
  ${heading4Style};
`;

const heading5Style = css<Heading>`
  ${baseHeadingStyle};
  font-size: 11px;
  line-height: 1.82;
  letter-spacing: 1px;
`;

const H5 = styled.h5<Heading>`
  ${heading5Style};
`;

export {
  P,
  H1,
  H2,
  H3,
  H4,
  H5,
  graphikFontFamily,
  petersburgFontFamily,
  bodyText1,
  bodyText2,
  bodyText3,
  heading1Style,
  heading2Style,
  heading3Style,
  heading4Style,
  heading5Style,
};
