import React from 'react';
import {
  Typography,
  TypographyProps,
} from '@allergan-data-labs/deprecated-universal-component-library/src/atoms/typography';

const Copyright2022 = ({ preset }: { preset?: TypographyProps['preset'] }) => (
  <Typography
    preset={
      preset || {
        s: 'body-S-regular',
        m: 'body-S-regular',
        l: 'body-M-regular',
      }
    }
    css={`
      color: inherit;
    `}
    data-testid="copyright-2022"
  >
    &copy; 2022 AbbVie. All rights reserved. All trademarks are the property of
    their respective owners.
    <br />
    Allergan Aesthetics reserves the right to alter or cancel the program with
    no advance notice or obligation.
  </Typography>
);

export { Copyright2022 };
