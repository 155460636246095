import React from 'react';
import styled from 'styled-components';

import { ABBVIE_URLS } from '@allergan-data-labs/deprecated-universal-component-library/src/constants/urls';
import { above } from '@allergan-data-labs/deprecated-universal-component-library/src/utils/mediaTemplate';
import { Typography } from '@allergan-data-labs/deprecated-universal-component-library/src/atoms/typography';
import { AlleButton as Button } from '@allergan-data-labs/deprecated-component-library/src/core/alleButton';
import { Close } from '@allergan-data-labs/deprecated-component-library/src/icons/close';
import { SimpleIconButton } from '@allergan-data-labs/deprecated-component-library/src/core/simpleIconButton';
import { alleColors } from '@allergan-data-labs/deprecated-component-library/src/colorPalette';
import { Container, showAnimation } from './shared';

const DefaultOptedInContainer = styled(Container)`
  align-items: flex-start;
  justify-content: center;
  max-width: 1024px;
  padding: 16px;
  box-shadow: 4px 4px 20px 4px rgba(0, 0, 0, 0.2);
  ${above.desktop`
    && {
      flex-direction: row;
      justify-content: flex-start;
    }
      padding: 24px 32px;
      animation: ${showAnimation('0', '100px')} linear 0.5s;
    `}
`;

const StyledLink = styled.a`
  vertical-align: baseline;
  color: ${alleColors.black};
  text-decoration: underline;
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  && {
    width: 100%;
    max-width: 380px;
    padding: 8px;
    height: 40px;
    margin: 16px auto auto auto;
    ${above.desktop`
      min-width: 125px;
      max-width: 125px;
      margin: auto auto auto 56px;
    `}
  }
`;

const StyledNormalText = styled(Typography)`
  text-align: left;
  margin: 0;
  ${above.mobile`
    margin: 0 25px;
  `}
  ${above.desktop`
    margin: 0;
  `}
`;

const StyledLinkButton = styled.button`
  cursor: pointer;
  border: 0;
  padding: 0;
  background: none;
  background-color: transparent;
  text-decoration: underline;
  & ${StyledNormalText} {
    margin: 0;
  }
  color: ${alleColors.black};
`;

const CloseButtonContainer = styled.div`
  height: 100%;
  margin-left: 16px;
  ${above.desktop`
    margin-left: 64px;
  `}
`;

const MobileContentWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

interface ContentBannerProps {
  showFullBanner: boolean;
  toggleFullBanner: () => void;
  onCloseBannerClick: () => void;
  onCookieSettingsClick: () => void;
  onPrivacyPolicyClick: () => void;
  snippetText: string;
}

const ShowMoreButton: React.FunctionComponent<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <StyledLinkButton onClick={onClick}>
      <StyledNormalText preset="body-M-regular">read more</StyledNormalText>
    </StyledLinkButton>
  );
};

const ShowLessButton: React.FunctionComponent<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <StyledLinkButton onClick={onClick}>
      <StyledNormalText preset="body-M-regular">read less</StyledNormalText>
    </StyledLinkButton>
  );
};

const BannerButton: React.FunctionComponent<{
  onClick: () => void;
  label: string;
  buttonType: 'contained' | 'outlined';
  testId: string;
}> = ({ onClick, label, buttonType, testId }) => {
  return (
    <StyledButton
      data-testid={testId}
      buttonType={buttonType}
      marginTop
      onClick={onClick}
    >
      {label}
    </StyledButton>
  );
};

const CloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <CloseButtonContainer>
      <SimpleIconButton
        data-testid={`banner-close-button`}
        aria-label="close-button"
        color={alleColors.gray[400]}
        onClick={onClick}
      >
        <Close />
      </SimpleIconButton>
    </CloseButtonContainer>
  );
};

const Snippet = ({
  text,
  onShowMoreClick,
}: {
  text: string;
  onShowMoreClick: () => void;
}) => {
  return (
    <StyledNormalText preset="body-M-regular">
      {text} <ShowMoreButton onClick={onShowMoreClick} />
    </StyledNormalText>
  );
};

const Content = ({
  onShowLessClick,
  onPrivacyPolicyClick,
}: {
  onShowLessClick: () => void;
  onPrivacyPolicyClick: () => void;
}) => {
  return (
    <StyledNormalText preset="body-M-regular">
      AbbVie may use and disclose online usage data about you collected
      automatically through online tracking technologies such as cookies,
      pixels, and web beacons (collectively, “cookies”). We use this data for
      multiple purposes, including for online targeted advertising
      (advertisements based on your interests inferred from your activity across
      other unaffiliated sites and services) and website analytics purposes, as
      well as to personalize content, save your preferences, provide social
      media features, and track the site’s performance, as further described in
      the{' “'}
      <StyledLink
        target="_blank"
        rel="noopener noreferrer"
        href={ABBVIE_URLS.cookiesAndTracking}
        onClick={onPrivacyPolicyClick}
      >
        Cookies and similar tracking and data collection technologies
      </StyledLink>
      {'” '}
      section of our Privacy Notice. We retain this data for as long as
      necessary to fulfill these purposes or as needed to comply with our record
      retention obligations. We do not sell your data, but we may disclose it to
      our marketing and advertising partners for purposes of online targeted
      advertising or for website analytics purposes. To opt out of the use or
      disclosure of your cookie-based personal data for online targeted
      advertising or to otherwise manage your preferences, please click on
      Cookie Settings. For additional information on the categories of data we
      collect, the purposes for their collection, disclosures to third parties,
      and data retention, please visit our{' '}
      <StyledLink
        target="_blank"
        rel="noopener noreferrer"
        href={ABBVIE_URLS.privacyPolicy}
        onClick={onPrivacyPolicyClick}
      >
        Privacy Notice
      </StyledLink>
      {'. '}
      <ShowLessButton onClick={onShowLessClick} />
    </StyledNormalText>
  );
};

const DefaultOptedInDesktopBanner: React.FunctionComponent<
  ContentBannerProps
> = ({
  showFullBanner,
  toggleFullBanner,
  onCookieSettingsClick,
  onCloseBannerClick,
  onPrivacyPolicyClick,
  snippetText,
}) => {
  return (
    <>
      {showFullBanner ? (
        <Content
          onShowLessClick={toggleFullBanner}
          onPrivacyPolicyClick={onPrivacyPolicyClick}
        />
      ) : (
        <Snippet text={snippetText} onShowMoreClick={toggleFullBanner} />
      )}
      <BannerButton
        onClick={onCookieSettingsClick}
        testId={'cookie-settings-button'}
        buttonType={'outlined'}
        label={'COOKIE SETTINGS'}
      />
      <CloseButton onClick={onCloseBannerClick} />
    </>
  );
};

const DefaultOptedInMobileBanner: React.FunctionComponent<
  ContentBannerProps
> = ({
  showFullBanner,
  toggleFullBanner,
  onCookieSettingsClick,
  onCloseBannerClick,
  onPrivacyPolicyClick,
  snippetText,
}) => {
  return (
    <>
      {showFullBanner ? (
        <MobileContentWrapper>
          <Content
            onShowLessClick={toggleFullBanner}
            onPrivacyPolicyClick={onPrivacyPolicyClick}
          />
          <CloseButton onClick={onCloseBannerClick} />
        </MobileContentWrapper>
      ) : (
        <MobileContentWrapper>
          <Snippet text={snippetText} onShowMoreClick={toggleFullBanner} />{' '}
          <CloseButton onClick={onCloseBannerClick} />
        </MobileContentWrapper>
      )}
      <BannerButton
        onClick={onCookieSettingsClick}
        testId={'cookie-settings-button'}
        buttonType={'outlined'}
        label={'COOKIE SETTINGS'}
      />
    </>
  );
};

export {
  DefaultOptedInDesktopBanner,
  DefaultOptedInMobileBanner,
  DefaultOptedInContainer,
};
