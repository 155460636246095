import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { above } from '@allergan-data-labs/deprecated-universal-component-library/src/utils/mediaTemplate';

import { bodyText2, H4 } from '../../core/typography/typographyDEPRECATED';
import { colors } from '../../core/colors';
import { Dialog } from '../dialog';
import { HtmlContainer } from '../htmlContainer';

export interface HtmlDialogProps {
  open: boolean;
  onClose?: () => void;
  html?: string;
  content?: ReactNode;
  title?: ReactNode;
}

const StyledDialog = styled(Dialog)`
  &.MuiDialog-root {
    .MuiDialog-container {
      .MuiPaper-root {
        display: inline-block;
        min-width: 100vw;
        width: 100vw;
        min-height: 100vh;
        margin: 0;
        border-radius: 0;
        ${above.desktop`
          margin: 48px;
          width: auto;
          min-width: 615px;
          min-height: 200px;
          border-radius: 4px;
        `}
      }
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;

  padding: 80px 33px;
  ${above.desktop`
    padding: 112px 124px;
  `}
`;

const Header = styled(H4).attrs({ as: 'h1' })`
  margin-top: 0;
  margin-bottom: 27px;
`;

const body = `
  ${bodyText2({ variant: 'bodyText2' })}
  color: ${colors.white};
  font-weight: normal;
  margin-bottom: 26px;
`;

const StyledHtmlContainer = styled(HtmlContainer)`
  h1,
  h2,
  h3,
  h4,
  h5,
  a,
  li {
    color: ${colors.white};
    font-weight: normal;
  }
  p {
    ${body}
  }
  div {
    ${body}
  }
`;

const HtmlDialog = ({
  html,
  content,
  title = (
    <>
      Usage and Important
      <br />
      Safety Information
    </>
  ),
  ...forwardProps
}: HtmlDialogProps) => {
  return (
    <StyledDialog dark scroll="body" {...forwardProps}>
      <Wrapper>
        {title ? (
          <Header textAlign="center" color={colors.white}>
            {title}
          </Header>
        ) : null}
        {html ? (
          <StyledHtmlContainer
            dangerouslySetInnerHTML={{ __html: html || '' }}
          />
        ) : (
          content ?? null
        )}
      </Wrapper>
    </StyledDialog>
  );
};

export { HtmlDialog };
