import { Machine } from 'xstate';
import { LoginSucceeded } from '@allergan-data-labs/deprecated-consumer-component-library/src/segment-tracking-plan/generated';

export interface AuthMachineContext {
  redirectURI?: string;
}

export interface AuthMachineSchema {
  states: {
    checkOktaLoggedIn: {};
    isLoggedIn: {};
    isNotLoggedIn: {};
    clearCacheBeforeLoggedIn: {};
    loggingOut: {};
    loggingOutNoRedirect: {};
    loggingOutCustomRedirect: {};
    loggingOutProfile: {};
  };
}

// TODO: refactor this type to be more explicit, not all type need the same payload
export type AuthMachineEvent = {
  type:
    | 'LOGIN_ALLERGAN_ADVANTAGE_TOKEN'
    | 'LOGIN_OKTA'
    | 'LOGOUT'
    | 'LOGOUT_NO_REDIRECT'
    | 'LOGOUT_CUSTOM_REDIRECT'
    | 'LOGOUT_PROFILE'
    | 'FORCE_FETCH_PROFILE';
  idToken?: string;
  accessToken?: string;
  redirectURI?: string;
  data?: {
    allerganAdvantageToken?: string;
  };
  loginSucceededTracking?: LoginSucceeded;
};

export const AuthState = {
  checkOktaLoggedIn: 'checkOktaLoggedIn',
  isLoggedIn: 'isLoggedIn',
  isNotLoggedIn: 'isNotLoggedIn',
  clearCacheBeforeLoggedIn: 'clearCacheBeforeLoggedIn',
  loggingOut: 'loggingOut',
  loggingOutProfile: 'loggingOutProfile',
} as const;

const authMachine = Machine<
  AuthMachineContext,
  AuthMachineSchema,
  AuthMachineEvent
>({
  id: 'auth',
  initial: 'checkOktaLoggedIn',
  context: {},
  states: {
    checkOktaLoggedIn: {
      invoke: {
        id: 'verifyOktaLoggedIn',
        src: 'verifyOktaLoggedIn',
        onDone: {
          target: 'isLoggedIn',
        },
        onError: 'isNotLoggedIn',
      },
    },
    isLoggedIn: {
      entry: ['syncSegmentIdentityTraits', 'populateCurrentUser'],
      exit: ['cleanupLocalStorage'],
      on: {
        LOGOUT: 'loggingOut',
        LOGOUT_PROFILE: 'loggingOutProfile',
        LOGOUT_NO_REDIRECT: 'loggingOutNoRedirect',
        LOGOUT_CUSTOM_REDIRECT: {
          target: 'loggingOutCustomRedirect',
          actions: ['setRedirectURI'],
        },
        FORCE_FETCH_PROFILE: { actions: ['populateCurrentUser'] },
      },
    },
    isNotLoggedIn: {
      exit: ['cleanupLocalStorage'],
      on: {
        LOGIN_OKTA: {
          target: 'clearCacheBeforeLoggedIn',
          actions: ['storeOktaToken', 'updateTrackLoginSucceeded'],
        },
      },
    },
    clearCacheBeforeLoggedIn: {
      invoke: {
        id: 'clearCache',
        src: 'clearCache',
        onDone: {
          target: 'isLoggedIn',
        },
        onError: {
          target: 'isLoggedIn',
        },
      },
    },
    loggingOut: {
      entry: ['logoutStartedTrack'],
      exit: ['clearBraze', 'clearSegment', 'clearUTMValues'],
      invoke: {
        id: 'logout',
        src: 'logout',
        onDone: {
          target: 'isNotLoggedIn',
          actions: ['logoutCompletedTrack'],
        },
        onError: {
          target: 'isNotLoggedIn',
        },
      },
    },
    loggingOutProfile: {
      entry: ['logoutStartedTrack'],
      exit: ['clearBraze', 'clearSegment', 'clearUTMValues'],
      invoke: {
        id: 'logout-profile',
        src: 'logoutProfile',
        onDone: {
          target: 'isNotLoggedIn',
          actions: ['logoutCompletedProfileTrack'],
        },
        onError: {
          target: 'isNotLoggedIn',
        },
      },
    },
    loggingOutNoRedirect: {
      entry: ['logoutStartedTrack'],
      exit: ['clearBraze', 'clearSegment'],
      invoke: {
        id: 'logout-no-redirect',
        src: 'logoutNoRedirect',
        onDone: {
          target: 'isNotLoggedIn',
          actions: ['logoutCompletedTrack'],
        },
        onError: {
          target: 'isNotLoggedIn',
        },
      },
    },
    loggingOutCustomRedirect: {
      entry: ['logoutStartedTrack'],
      exit: ['redirectCustomLocation', 'clearBraze', 'clearSegment'],
      invoke: {
        id: 'logout-custom-redirect',
        src: 'logoutNoRedirect',
        onDone: {
          target: 'isNotLoggedIn',
          actions: ['logoutCompletedTrack'],
        },
        onError: {
          target: 'isNotLoggedIn',
        },
      },
    },
  },
});

export { authMachine };
