import React from 'react';
import { datadogLogs, HandlerType, LogsEvent } from '@datadog/browser-logs';
import { datadogRum, RumEvent } from '@datadog/browser-rum';

import {
  __DEV__,
  Application,
  getDatadogConfig,
} from '@allergan-data-labs/base-common-config';

import { redactSensitiveDataFromURL } from '../utils/format';

let logger = datadogLogs.logger;

const redactData = (event: LogsEvent | RumEvent) => {
  // Remove sensitive data from URLs
  if (event?.view?.url) {
    event.view.url = redactSensitiveDataFromURL(event.view.url);
  }
  // @ts-ignore
  if (event?.resource?.url) {
    // @ts-ignore
    event.resource.url = redactSensitiveDataFromURL(event.resource.url);
  }
};

const dataDogInit = (application: Application, version: string) => {
  const dataDogConfig = getDatadogConfig(application);

  // RUM
  datadogRum.init({
    clientToken: dataDogConfig.clientToken,
    applicationId: dataDogConfig.applicationId,
    trackInteractions: dataDogConfig.trackInteractions,
    service: dataDogConfig.service,
    version: version,
    datacenter: 'us',
    sampleRate: 100,
    silentMultipleInit: true,
    env: dataDogConfig.env,
    beforeSend: redactData,
  });

  // LOGS
  datadogLogs.init({
    clientToken: dataDogConfig.clientToken,
    datacenter: 'us',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    silentMultipleInit: true,
    version,
    env: dataDogConfig.env,
    service: dataDogConfig.service,
    beforeSend: redactData,
  });

  datadogLogs.setLoggerGlobalContext({
    clientApplication: application,
  });

  logger = datadogLogs.logger;

  if (__DEV__) {
    datadogLogs.logger.setHandler(HandlerType.console);
  }

  return {
    rum: datadogRum,
    logger,
  };
};

interface DataDogProps {
  application: Application;
  children?: React.ReactNode;
  version: string;
}

const Datadog: React.FC<DataDogProps> = ({
  application,
  children,
  version,
}) => {
  React.useEffect(() => {
    dataDogInit(application, version);
  }, [application, version]);

  return <>{children}</>;
};

export { Datadog, logger, datadogRum };
