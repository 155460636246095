import React from 'react';
import { RichText } from '@allergan-data-labs/deprecated-component-library/src/components/richText/richText';
import { IBlockAuthenticationFlowFields } from '@allergan-data-labs/core-contentful-rest';
import { BehaviorSubject } from 'rxjs';
import { Entry } from 'contentful';
import { BlockAuthenticationFlow } from '@allergan-data-labs/core-contentful-graphql';
import { Routes } from '../../constants/routes';

const authenticationRoute = new RegExp(
  `^((?:${Routes.registrationPhoneNumber}$)|(?:${
    Routes.registrationComplete
  })|(?:${Routes.login}$)|(?:${
    Routes.forgotPassword
  }$)|(?:${Routes.userResetPassword.replace(':token', '')}[a-zA-Z0-9-]+$))`
);

export const isAuthenticationRoute = (route: string) =>
  authenticationRoute.test(route);

export const mapGqlResponseToAuthenticationStore = (
  authenticationFlow: BlockAuthenticationFlow
): AuthenticationFlow => {
  const {
    header,
    authenticationSuccessScreen,
    authenticationSuccessAction,
    authenticationSuccessRedirectUrl,
    formBottomCopy,
  } = authenticationFlow;
  return {
    header: header
      ? {
          logo: header?.logo?.cloudinaryImage?.[0].secure_url
            ? {
                url: header?.logo?.cloudinaryImage?.[0].secure_url,
                alt: header?.logo?.alt || '',
              }
            : undefined,
          backgroundImageUrl:
            header?.backgroundImage?.cloudinaryImage?.[0].secure_url ||
            undefined,
          backgroundColor: header?.backgroundColor || undefined,
        }
      : null,
    formBottomCopy: formBottomCopy ? (
      <RichText
        richText={formBottomCopy.json}
        links={formBottomCopy?.links?.entries.hyperlink}
        paragraphPreset="body-S-regular"
      />
    ) : null,
    onSuccessAction: authenticationSuccessAction || null,
    successScreen: authenticationSuccessScreen
      ? {
          copy: (
            <RichText
              richText={authenticationSuccessScreen?.copy?.json}
              links={
                authenticationSuccessScreen.copy?.links?.entries?.hyperlink
              }
            />
          ),
          ctas: authenticationSuccessScreen?.ctaCollection?.items?.map(
            (cta) => ({
              text: cta?.buttonCopy || undefined,
              url: cta?.url || undefined,
              forceOpenSameTab: cta?.forceOpenSameTab || undefined,
            })
          ),
          additionalCopy: (
            <RichText
              richText={authenticationSuccessScreen?.additionalCopy?.json}
              links={
                authenticationSuccessScreen?.additionalCopy?.links?.entries
                  ?.hyperlink
              }
              paragraphPreset="body-S-regular"
            />
          ),
        }
      : null,
    onSuccessRedirect: authenticationSuccessRedirectUrl || null,
  };
};

export const mapRestResponseToAuthenticationStore = (
  authenticationFlow: Entry<IBlockAuthenticationFlowFields>
): AuthenticationFlow => {
  const {
    header,
    authenticationSuccessScreen,
    authenticationSuccessAction,
    authenticationSuccessRedirectUrl,
    formBottomCopy,
  } = authenticationFlow.fields;

  return {
    header: header
      ? {
          logo: header?.fields.logo?.fields.cloudinaryImage?.[0].secure_url
            ? {
                url: header?.fields.logo?.fields.cloudinaryImage?.[0]
                  .secure_url,
                alt: header?.fields.logo?.fields.alt || '',
              }
            : undefined,
          backgroundImageUrl:
            header?.fields.backgroundImage?.fields.cloudinaryImage?.[0]
              .secure_url,
          backgroundColor: header?.fields.backgroundColor,
        }
      : null,
    formBottomCopy: formBottomCopy ? (
      <RichText richText={formBottomCopy} paragraphPreset="body-S-regular" />
    ) : null,
    onSuccessAction: authenticationSuccessAction || null,
    successScreen: authenticationSuccessScreen
      ? {
          copy: (
            <RichText richText={authenticationSuccessScreen?.fields.copy} />
          ),
          ctas: authenticationSuccessScreen?.fields.cta?.map((cta) => ({
            text: cta.fields.buttonCopy,
            url: cta.fields.url,
            forceOpenSameTab: cta.fields.forceOpenSameTab,
          })),
          additionalCopy: (
            <RichText
              richText={authenticationSuccessScreen?.fields.additionalCopy}
              paragraphPreset="body-S-regular"
            />
          ),
        }
      : null,
    onSuccessRedirect: authenticationSuccessRedirectUrl || null,
  };
};

type AuthenticationHeader = {
  logo?: { url: string; alt: string };
  backgroundImageUrl?: string;
  backgroundColor?: string;
};

type AuthenticationSuccessScreen = {
  copy: React.ReactNode | null;
  additionalCopy: React.ReactNode | null;
  ctas?: { text?: string; url?: string; forceOpenSameTab?: boolean }[];
};

export type AuthenticationFlow = {
  header: AuthenticationHeader | null;
  formBottomCopy: React.ReactNode | null;
  onSuccessAction: string | null;
  successScreen: AuthenticationSuccessScreen | null;
  // NOTE: this property should be ignored if there is a successScreen
  onSuccessRedirect: string | null;
};

/**
 * Used to pass information about authentication flow that may already be fetched on the page (to prevent an extra fetch/loading at an authentication route)
 * Note: Should clear this store when unmounting any authentication component (on refresh of authentication pg will refetch this data based on properties in location state)
 * **/
export const authenticationFlow =
  new BehaviorSubject<AuthenticationFlow | null>(null);
