import React from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import styled from 'styled-components';

import { AlleLogo } from '@allergan-data-labs/deprecated-component-library/src/icons/alleLogo';
import { Close } from '@allergan-data-labs/deprecated-component-library/src/icons/close';
import { HeaderContainer } from '@allergan-data-labs/deprecated-component-library/src/header/headerContainer';
import { ThemeTypes } from '@allergan-data-labs/deprecated-component-library/src/components/theme';
import { above } from '@allergan-data-labs/deprecated-universal-component-library/src/utils/mediaTemplate';

import { Routes } from '../../constants/routes';
import { routeConfig } from '../../routeConfig';
import { useSegment } from '@allergan-data-labs/deprecated-consumer-component-library/src/segment/segmentContext';
import { useAuth } from '../../auth/AuthProvider';
import { AuthState } from '../../auth/authMachine';
import { AuthenticationLocationState } from '../../auth/locationStateTypes';

const LogoLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseLink = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 11px 7px 7px;
  margin: -7px 0 -7px -7px;

  ${above.desktop`
    padding: 7px;
    margin: -7px;
  `}
`;

interface HeaderWithCloseProps extends ThemeTypes {
  logoLinkTo?: string;
  closeLinkTo?: string;
}

const HeaderWithClose: React.FunctionComponent<HeaderWithCloseProps> = ({
  dark,
  logoLinkTo = '/',
  closeLinkTo = '/',
}) => {
  const { state, pathname } = useLocation<
    | ({
        from?: string | { pathname?: string };
      } & AuthenticationLocationState)
    | undefined
  >();
  const { trackConsumer } = useSegment();
  const { authState } = useAuth();

  const getCloseLinkTo = (): string | { pathname: string } => {
    let closeLinkToOverride: {
      pathname: string;
    } | null = null;
    if (state?.from) {
      if (
        typeof state.from === 'string' ||
        (state.from as any) instanceof String
      ) {
        // from is a string, create a location description with that string.
        closeLinkToOverride = { pathname: state.from as string };
      } else if (state.from.pathname) {
        // from is object with pathname defined.
        closeLinkToOverride = state.from as { pathname: string };
      }
    }
    if (state?.authFlowState?.from) {
      closeLinkToOverride = { pathname: state.authFlowState?.from };
    }

    if (closeLinkToOverride) {
      if (pathname !== Routes.login) {
        return closeLinkToOverride;
      }
      // Currently at /login and an override is defined.
      // We can assume the user is not logged in, otherwise login page
      // would redirect to account dashboard.
      // We want to avoid the close button going to a private route, because
      // they will get redirected back to login and this button will do nothing.
      const overridePathname = closeLinkToOverride.pathname;
      if (
        routeConfig.find((config) => {
          return matchPath(overridePathname, {
            path: config.path,
            exact: true,
          });
        })?.secure
      ) {
        return closeLinkTo;
      }
      // Override pathname is not secure, safe to link there from login.
      return closeLinkToOverride;
    }
    // No override defined, go to default close link
    return closeLinkTo;
  };

  const trackCloseClick = () => {
    const link = getCloseLinkTo();
    trackConsumer()?.siteNavigated({
      event: {
        action_source: 'consumer web',
        activator: 'Close',
        explainer: `Tracking user clicked close button on ${pathname}`,
        invoked_by: 'consumer',
        user_type: 'consumer',
        user_status: authState.matches(AuthState.isLoggedIn)
          ? 'logged_in'
          : 'logged_out',
        event_type: 'engagement',
      },
      navigation_type: 'top navigation',
      site_type: 'consumer web',
      event_source_url: `${window.location.href}`,
      text: 'X',
      url: typeof link === 'string' ? link : link.pathname,
    });
  };

  return (
    <HeaderContainer variant={dark ? 'dark' : 'light'}>
      <LogoLink to={logoLinkTo}>
        <AlleLogo dark={dark} />
      </LogoLink>
      <CloseLink to={getCloseLinkTo} onClick={trackCloseClick}>
        <Close dark={dark} />
      </CloseLink>
    </HeaderContainer>
  );
};

export { HeaderWithClose };
